import axios from 'axios';
import {baseUrl} from "@/utils/main";
import router from "@/router";

//用 Axios Instance 管理 API
/*
    集中設定 Request Config
    支援攔截器，可在 then/catch 前做額外處理
    封裝 API 易於管理
*
* */
const instance = axios.create({
    //baseURL: baseUrl(),
    headers: {'Content-Type':'application/json'},
    timeout:10000
});

//攔截器
instance.interceptors.request.use(
    async config => {
        const csrfToken = sessionStorage.getItem('csrf_token');
        config.headers['csrf_token']=`${csrfToken}`;
        // config.headers = {
        //     'csrf_token': `${csrfToken}`,
        // };
        config.withCredentials = true;
        //console.log('config=',config);
        return config;
    }, error => {
        return Promise.reject(error);
    }
);

//攔截器
instance.interceptors.response.use(
    response => {
        return response;
    }, async error => {
        //console.log(error);
        if (error.response) {
            console.log(error.response);
            switch(error.response.data.status) {
                case 403:
                    if(error.response.data.msg === 'Access Denied') {
                        //alert('請重新登入');
                        alert(this.$t('LoginAgain'));
                        sessionStorage.setItem('csrf_token', null);
                        router.push({name: 'Login'});
                    } else if(error.response.data.msg === 'Forbidden') {
                        //alert('您無此權限');
                        alert(this.$t('NoSuchPermission'));
                        //sessionStorage.setItem('csrf_token', null);
                        //router.push({name: 'login'});
                    }
                    break
                case 401:
                    if(error.response.data.msg === 'Forbidden,cookie is null') {
                        console.log('Forbidden,cookie is null');
                        //sessionStorage.setItem('csrf_token', null);
                        sessionStorage.removeItem('csrf_token');
                        //alert('請重新登入');
                        alert(this.$t('LoginAgain'));
                        router.push({name: 'Login'});
                    }
                    else{
                        if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                            alert(error.response.data.msg);
                        }
                    }
                    break;
                case 417:
                    if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                        alert(error.response.data.msg);
                    }
                    break;
                case 500:
                    if(error.response.data.msg !== null && error.response.data.msg !== undefined) {
                        alert(error.response.data.msg);
                    }
                    break
                default:
                    if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                        alert(error.response.data.msg);
                        console.log("alert default");
                    }
                    break;
            }
            if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                console.log(error.response.data.msg);
            }

            return Promise.reject(error.response);
        } else {
            console.log('error:' + error);
            return Promise.reject(error);
        }
    }
);

// 封裝請求
// 此處的instance為我們create的實體
export default function(method, url, data, config) {
    method = method.toLowerCase();
    switch (method) {
        case "post":
            return instance.post(url, data, config);
        case "get":
            return instance.get(url, { params: data });
        case "delete":
            return instance.delete(url, config);
        case "put":
            return instance.put(url, data, config);
        case "patch":
            return instance.patch(url, data);
        default:
            console.log(`未知的 method: ${method}`);
            return null;
    }
}
