<template>
    <div style="background-color: #405366; height: calc(100vh - 3px);" >
        <router-link style="text-decoration: none; color: inherit;" :key="$store.state.menu.id" to="/dashboard" >
          <div style="line-height: 50px; color: #ffb980; font-weight: bold"><!--<svg-icon iconName="evcharger" style="height: 1.4em;" />-->
            <img src="../../assets/images/evems.png" width="30px" style="display: inline; vertical-align: middle; margin: 10px;" v-if="isCollapse"/>
            <img src="../../assets/images/evems.png" width="46px" style="display: inline; vertical-align: middle; margin: 10px;" v-else />
            <span v-if="isCollapse"></span>
            <span style="font-size:1.2em; vertical-align: middle;" v-else>{{$t('CPMS')}}</span> <!--(原)充電樁服務管理系統-->
          </div>
        </router-link>

        <el-scrollbar>

          <!--下拉選單,選單的value會存到il18n.locale-->
          <div class="locale-changer" style="margin-top:7px; margin-bottom: 5px;" v-if="!isCollapse">
            <p style="margin-left: 2rem;">
            {{$t('UseLang')}}
            <select v-model="$i18n.locale">
            <!--<option value="tw" selected="selected">繁體中文</option>-->
            <option value="tw" selected="selected">繁體中文</option>
            <option value="en">English(test)</option>
            <option value="ja">日本語(test)</option>
            <!--
            <option value="cn">简体中文</option>
            <option value="ko">한국어</option>       -->
            </select>
            </p>
          </div>
          <!--下拉選單,選單的value會存到il18n.locale-->
            <el-menu
                class="el-menu-vertical-demo"
                mode="vertical"
                background-color="#405366"
                text-color="#fff"
                active-text-color="#ffb980"
                :default-active="$route.name"
                :collapse="isCollapse"
                :collapse-transition="false"
            >
                <MenuTree :menuData="$store.state.menu"></MenuTree>
            <br>
            <br>
            <!--日期顯示-->
            <span style="display: flex;flex-direction:column;" v-if="!isCollapse">{{ $d(now_time, 'date') }}</span>
            <!--日期顯示-->
            <!--時鐘顯示-->
            <span style="display: flex;flex-direction:column;" v-if="!isCollapse">{{ $d(now_time, 'time') }}</span>
            <!--時鐘顯示-->
            <br>
            </el-menu>
        </el-scrollbar>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MenuTree from './MenuTree.vue'

export default {
    name: 'Sidebar',
    data() {
      return {
        now_time: undefined,
      }
    },
    //////目前時間更新////////
    mounted() {
      this.interval = setInterval( () => { this.now_time = new Date(); }, 1000 );
    },
    beforeDestroy() { clearInterval( this.interval);
    },
    //////目前時間更新////////
    components: {
      MenuTree
    },
    created() {
      console.log('Sidebar created,call getMenuByCurrentAccount');
      this.$store.dispatch("getMenuByCurrentAccount")
      console.log("$store.state.menu",this.$store.state.menu)
    },
    computed: {
      ...mapGetters([
        'sidebar'
      ]),
      isCollapse() {
        return !this.sidebar.open
      }
    },
 }

</script>

<style>
  /* .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 240px;
    min-height: 400px;
  } */

  .scrollbar-wrapper {
    overflow-x: hidden !important;
    }

  /* .el-scrollbar {
        height: 100%;
    } */
</style>
