<template>
  <div>
    <el-row>
      <div @click="toggleCollapse" style="float: left; padding:7px 3px;">
        <svg-icon
          class="hamburger"
          :iconName="isCollapse ? 'menu_unfold':'menu_fold'"
        />
      </div>
      <div style="font-weight: bold;">
        <!--{{ currentRouteName }} 替換語系-->
        <span >  {{ $t(currentRouteName) }} </span>
        <div style="float: right;">
<!--         <span style="text-decoration: none; cursor: pointer" @click="toDoList()">-->
<!--            <svg-icon-->
<!--              style="height:1.5em; width:2em;"-->
<!--              iconName='bell'-->
<!--            />-->
<!--          </span>-->
<!--          <span v-if="this.unreadNum !== '0'" style="color:red;">-->
<!--            {{this.unreadNum }}-->
<!--          </span>-->
          <!--<span v-else>
            {{this.unreadNum }}
          </span>-->
          <el-tooltip class="item" effect="dark" :content="$store.state.currentAccount!=='undefined'?$store.state.currentAccount.serviceInfo.name:'營運商'" placement="bottom">
          <span style="text-decoration: none; cursor: pointer">
            <svg-icon
                style="height:1.5em; width:2em;"
                iconName='company'
            />
          </span>
          </el-tooltip>
          <span class="tooltip-content" v-if="$store.state.currentAccount">
            {{ $store.state.currentAccount.serviceInfo.name }}
          </span>
          <el-tooltip class="item" effect="dark" :content="$store.state.currentAccount!=='undefined'?$store.state.currentAccount.name:'成員帳號'" placement="bottom">
          <span style="text-decoration: none; cursor: pointer">
            <svg-icon
                style="height:1.5em; width:2em;"
                iconName='user'
            />
          </span>
          </el-tooltip>
          <span class="tooltip-content" v-if="$store.state.currentAccount">
            {{ $store.state.currentAccount.name }}
          </span>
          <el-tooltip class="item" effect="dark" content="登出" placement="bottom">
          <span style="text-decoration: none; cursor: pointer" @click="logoutCheck()">
          <!-- <router-link
            style="text-decoration: none; color: inherit;"
            :to="resolvePath('logout')"
            > -->
            <svg-icon
              style="height:1.5em; width:2em;"
              iconName='logout'
            />
          </span>
          </el-tooltip>
          <!-- </router-link> -->
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import router from "@/router";
import * as main from "@/utils/main";
import {refreshToken, stopRefreshToken, logout, justDoRefreshToken} from "@/utils/auth";

const authUrl = main.authUrl();

export default {
  name: 'Header',
  data() {
    return {
      isCollapse: false,
      unreadNum: '',
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
   created () {
    // console.log('queryProject')
    // this.axios.get('/api/todo/getToDoListUnreadCount').then(res => {
    //   console.log(res.data)
    //   this.unreadNum = res.data
    // })
    // console.log('queryProject')
  },
  methods: {
    toggleCollapse() {
      this.$store.dispatch('toggleSidebar')
      this.isCollapse = !this.isCollapse
    },
    resolvePath(url) {
      return this.$router.options.base + url;  // 防止導成相對路徑
    },
    logoutCheck() {
      /*替換語系
      this.$confirm('確定登出系统嗎？', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消', */
      this.$confirm(this.$t('ConfirmLogout'), this.$t('Hint'), {
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.logout()
      })
    },
    async logout() {

      await this.axios.post(authUrl+"logout",{},{ withCredentials: true }
      ).then(response => {
        if (response != null) {
          localStorage.clear() // 將 localstorage 所有屬性移除
          sessionStorage.clear() // 將 session storage 所有屬性移除
          stopRefreshToken(); //將更新token定時器刪除
          alert(this.$t('YouHaveLoggedOut'));
          router.replace({name: 'Login'});
        }

      }).catch(err => {
        console.log(err)
      })

    },
    toDoList () {
      if (this.$route.path !== '/ToDoList') {
        this.$router.push('/ToDoList');
      }
    }
  }

}

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.hamburger {
  vertical-align: baseline !important;
  width: 1.5em !important;
  height: 1.5em !important;
}
.hamburger:hover {
  cursor: pointer;
}

@media (max-width: 767.98px) { 
    .tooltip-content {
        display: none;
    }
    
}

@media (min-width: 768px) { 
  .tooltip-content {
        display: inline;
    }
}

</style>
