<template>
    <svg class="svg-icon" aria-hidden="true">
        <use :xlink:href="`#${iconName}`" />
    </svg>

</template>
<script>
export default {
    name: "SvgIcon",
    props: {
      iconName: {
        type: String,
        required: true
      }
    },
};
</script>

<style scoped>
.svg-icon {
  width: 2.2em;
  height: 1.1em;
  vertical-align: -0.2em;
  fill: currentColor !important;
  overflow: hidden;
}
</style>