import axios from 'axios'
import Element from 'element-ui'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import store from './store'
//import locale from 'element-ui/lib/locale/lang/zh-TW' // lang i18n
import '@/assets/css/index.css'; // css
import '@/assets/style/theme/index.css'
import "element-ui/lib/theme-chalk/index.css"; // 重要! 沒有的話 element ui 樣式會不正常
import './assets/icons'; // icon
//import i18n from './lang/lang'
// import VueJsonPretty from 'vue-json-pretty/lib/vue-json-pretty'
// import 'vue-json-pretty/lib/styles.css'
// import { VueQuillEditor } from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import Fragment from 'vue-fragment'; // 解決 el-menu el-submenu 中間有一層 div , collapse 無法渲染成功問題
// import VueTagsInput from '@johmun/vue-tags-input'; // vue-tags-input

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel);

import ExcelXlsx from "@/views/common/ExcelXlsx"
Vue.component('ExcelXlsx', ExcelXlsx);

import ExcelXlsxes from "@/views/common/ExcelXlsxes"
Vue.component('ExcelXlsxes', ExcelXlsxes);

/* 引入vuetify並使用Vuetify*/
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify);
const vuetify = new Vuetify();
//const opts = {}
//export default new Vuetify(opts);

// use echarts
import * as echarts from "echarts"; // v5.0以上的版本要用此  不可以刪除
import EChartsVue from "vue-echarts"
// import "echarts-gl";
//import "@vue/composition-api";
Vue.prototype.$echarts = echarts;
Vue.component("v-chart", EChartsVue); // 註冊為 global component

Element.Dialog.props.closeOnClickModal.default = false // 全域禁止dialog點擊空白處關閉

//引入提示訊息元件(全局元件)
import promptMessages from "@/components/messages/promptMessages.vue"
Vue.component(promptMessages.name,promptMessages);
// import '@/utils/components.js'; // 自定义组件 js


//////////////////////////因應多語系i18n+ElmentUI的修改///////////////////////
////////////////////////////////////////////////////////////////////////////

import i18n from "./locales/i18n"
//Vue.use(Element, { locale }) // ElementUI設繁體 --------->-(這會UI固定繁中故移除)
Vue.use(Element, {i18n: (key,value) => i18n.t(key,value)})

////////////////////動態熱重載語言環境///////////////////////////////////////
/*加載所有語言環境並記住整個context*/
function loadMessages() {
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );

  return { context, messages };
}
const { context, messages } = loadMessages();

/*Hot updates熱更新*/
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}
/////////////////動態熱重載語言環境(END)//////////////////////

/////////////////////因應多語系i18n+ElmentUI的修改(END)///////////////////////
////////////////////////////////////////////////////////////////////////////

//use axios
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios
// axios.defaults.baseURL = '/'
axios.defaults.withCredentials = true

import _ from 'lodash'
Vue.prototype._ = _

Vue.use(Fragment.Plugin)

Vue.config.productionTip = false

// moment
// import moment from 'moment'
// moment.locale('zh-tw')
import moment from 'moment-timezone'
Vue.prototype.$moment = moment


//axios 攔截器
axios.interceptors.request.use(
    async config => {
      const csrfToken = sessionStorage.getItem('csrf_token');
      config.headers['csrf_token']=`${csrfToken}`;
      // config.headers = {
      //     'csrf_token': `${csrfToken}`,
      // };
      config.withCredentials = true;
      //console.log('config='+config);
      return config;
    }, error => {
      return Promise.reject(error);
    }
);

//axios 攔截器
axios.interceptors.response.use(
    response => {
      return response;
    }, async error => {
      //console.log(error);
      if (error.response) {
        console.log(error.response);
        if(error.response.status) {
          switch(error.response.status) {
            case 403:
              if(error.response.statusText === 'Access Denied') {
                //alert('請重新登入');
                Element.Message({
                  message: '請重新登入',
                  type: "error",
                  duration: 5000,
                  showClose: true
                });
                sessionStorage.setItem('csrf_token', null);
                router.push({name: 'Login'});
              } else if(error.response.statusText === 'Forbidden') {
                //alert('您無此權限,請先登入');
                //sessionStorage.setItem('csrf_token', null);
                //router.replace({name: 'login'});
              }
              break
            case 401:
              if(error.response.data.msg === 'Forbidden,cookie is null') {
                console.log('Forbidden,cookie is null');
                //sessionStorage.setItem('csrf_token', null);
                sessionStorage.removeItem('csrf_token');
                //alert('請重新登入');
                Element.Message({
                  message: '請重新登入',
                  type: "error",
                  duration: 5000,
                  showClose: true
                });
                router.push({name: 'Login'});
              }
              else{
                if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                  Element.Message({
                    message: error.response.data.msg,
                    type: "error",
                    duration: 5000,
                    showClose: true
                  });
                }
              }
              break;
            case 417:
              if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                Element.Message({
                  message: error.response.data.msg,
                  type: "error",
                  duration: 5000,
                  showClose: true
                });
              }
              break;
            case 500:
              if(error.response.data.msg !== null && error.response.data.msg !== undefined) {
                Element.Message({
                  message: error.response.data.msg,
                  type: "error",
                  duration: 5000,
                  showClose: true
                });
              }
              break
            default:
              if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
                //alert(error.response.data.msg);
                console.log("alert default");
              }
              break;
          }
        } else {
          if(error.response.data.msg !== null && error.response.data.msg !== undefined ) {
            Element.Message({
              message: error.response.data.msg,
              type: "error",
              duration: 5000,
              showClose: true
            });
          }
        }
        return Promise.reject(error.response);
      } else {
        console.log('error:' + error);
        return Promise.reject(error);
      }
    }
);

// 載入 vue2-leaflet，依照自己需要載入組件
import { LControl, LIcon, LMap, LMarker, LPopup, LTileLayer } from "vue2-leaflet"
// 載入 css
import "leaflet/dist/leaflet.css"

// 啟用載入的各組件
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-icon", LIcon);
Vue.component("l-control", LControl);

//支援touch event for mobile phone like iPhone 16
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// 設定預設 icon
import { Icon } from "leaflet"
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = false;

// 定義全域變數
//Vue.prototype.$isAuthenticated = false

/* 設定全域 */
new Vue({
  vuetify,
  router,
  store,
  i18n,  //i18n
  render: h => h(App)
}).$mount('#app')
