import req from "@/utils/api";
import {authUrl} from "@/utils/main";
import Vue from "vue";
import router from "@/router";

let refreshTokenTimer = null;
const refreshTokenInterval = 115*60*1000;     // 115分鐘, JWT有效120分鐘
//const refreshTokenInterval = 20*1000;     // 20秒
const getError = (error) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

/**
 * 只定時刷新,不先更新JWT token
 */

export const justDoRefreshToken = async () =>{
    console.log('justDoRefresh');
    //await stopRefreshToken();
    refreshTokenTimer = window.setInterval(function () {
        console.log('auth,just refreshToken by interval...');
        req('post',authUrl()+"refreshToken", {}, {withCredentials: true})
            .then(response => {
                console.log("auth/refreshToken Interval success!");
                sessionStorage.setItem('csrf_token', response.data.data);
            })
            .catch(error => {
                console.log("auth/refreshToken Interval error => error.data:" + error.data);
            });
    }, refreshTokenInterval);


}


/**
 *
 * 定時刷新JWT Token
 * 時間參數:refreshTokenInterval
 */

export const  refreshToken = async () => {
    console.log('auth,refreshToken...');
    setTimeout(doRefresh,3000);

};


async function doRefresh() {
    console.log('doRefreshToken');
    await req('post',authUrl()+"refreshToken", {withCredentials: true})
        .then(response => {
            console.log("auth/refreshToken success!");
            sessionStorage.setItem('csrf_token', response.data.data);
        })
        .catch(error => {
            if (error.response) {
                console.log(error);
                console.log(error.response);
                switch(error.response.data.status) {
                    case 401:
                        //main.goToPage("/login.html");
                        alert('請重新登入');
                        break;
                    case 403:
                        if(error.response.data.msg === 'Access Denied') {
                            //main.goToPage("/login.html");
                            alert('請重新登入');
                        }
                        break;
                    default:
                        break;
                }
            }
            console.log("auth/refreshToken error => hello error.data:" + error.data);
        });
    refreshTokenTimer = window.setInterval(function () {
        console.log('auth,refreshToken by interval...');
        req('post',authUrl()+"refreshToken", {}, {withCredentials: true})
            .then(response => {
                console.log("auth/refreshToken Interval success!");
                sessionStorage.setItem('csrf_token', response.data.data);
            })
            .catch(error => {
                console.log("auth/refreshToken Interval error => error.data:" + error.data);
            });
    }, refreshTokenInterval);


}

/**
 *
 * 停止刷新JWT Token
 */
export const  stopRefreshToken = async () =>{
    console.log('auth,stopRefreshToken...');
    if(refreshTokenTimer)
    {
        window.clearInterval(refreshTokenTimer);
    }
};

export const logout = async () =>{
    console.log("call logout");
    await req('post',authUrl()+"logout",{},{ withCredentials: true }
    ).then(response => {
        if (response != null) {
            console.log(response);
            localStorage.clear() // 將 localstorage 所有屬性移除
            sessionStorage.clear() // 將 session storage 所有屬性移除
            stopRefreshToken(); //將更新token定時器刪除
            router.replace({name: 'Login'});
        }
    }).catch(err => {
        console.log(err)
    })
};
