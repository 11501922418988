<template>
  <fragment>
    <template v-for="menu in this.menuData">
      <el-submenu style="text-align: left" :key="menu.id" :index="menu.id" v-if="menu.children.length > 0">
          <template slot="title">
              <svg-icon :iconName="menu.icon" />
              <span class="menu-title" slot="title" >{{ $t(menu.title) }}</span><!--(原)menu.title-->
          </template>
          <menu-tree :menuData="menu.children"></menu-tree>
      </el-submenu>
      <router-link style="text-decoration: none; color: inherit;" :key="menu.id" :to="resolvePath(menu.url)" v-else>
      <el-menu-item style="text-align: left" :key="menu.id" :index="menu.title">
          <svg-icon :iconName="menu.icon" />
          <span class="menu-title"  slot="title" >{{ $t(menu.title) }}</span> <!--(原)menu.title-->
      </el-menu-item>
      </router-link>
    </template>
  </fragment>
</template>

<script>

export default {
  props: ['menuData'],
  name: 'MenuTree',
  methods: {
    resolvePath(url) {
      //return this.$router.options.base + url;  // 防止導成相對路徑
      return '/' + url;  // 防止導成相對路徑
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.menu-title {
  font-size: 15px;
}
</style>
