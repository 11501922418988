/*
Global Variable
*/
import axios from "axios";

const currentUrl = window.location.href;

export const baseUrl =  (() =>  {
    let lhPort = window.location.port != 'undefined'?window.location.port:443
    return (currentUrl.indexOf("localhost") > 0 ) ? "http://localhost:"+lhPort+"/" : "/evcharge/";
});


export const authUrl = (() =>  {
    let lhPort = window.location.port != 'undefined'?window.location.port:443
    return (currentUrl.indexOf("localhost") > 0 ) ? "http://localhost:"+lhPort+"/auth/v1/" : "/evcharge/auth/v1/";
});

export const apiUrl = (() =>  {
    let lhPort = window.location.port != 'undefined'?window.location.port:443
    return (currentUrl.indexOf("localhost") > 0 ) ? "http://localhost:"+lhPort+"/api/v1/" : "/evcharge/api/v1/";
});


export const getAxios = ((team_name) =>  {
    const csrfToken = sessionStorage.getItem('csrf_token');
    const instance = axios.create({
        headers: {
            'csrf_token': `${csrfToken}`,
            'team_name':team_name,
        },
        timeout: 20000
    });

    return instance;
});




/*
Global Function
*/
export function goToPage(page) {
    const numOfUrl = currentUrl.lastIndexOf("/");
    const newUrl = currentUrl.substring(0,numOfUrl).concat(page);
    return window.location.replace(newUrl);
}
