import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'//保存store的state內容,避免按refresh(F5)後會消失
import SecureLS from "secure-ls";//保存store在localStorage並加密
const ls = new SecureLS({ isCompression: false,  });
//import * as Cookies from 'js-cookie'
import * as main from "@/utils/main";
import {apiUrl} from "@/utils/main";
import {commit} from "lodash/seq";

Vue.use(Vuex)


export default new Vuex.Store({
  // plugins: [createPersistedState()],
  plugins: [createPersistedState({
    storage:{
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })],
  state: {
    isAuthenticated: false,
    currentAccount: undefined,  // 目前登入帳號資訊
    chargingStations: undefined, //帳入帳號所屬營運商資訊
    chargingPoints: undefined,
    chargingConnectors: undefined,
    menu: require('../../../main/resources/config/menu.json'), // 選單目錄(當前使用者可視)
    allMenu: require('../../../main/resources/config/menu.json'), // 所有選單目錄
    //menu: [], // 選單目錄(當前使用者可視)
    //allMenu: [], // 所有選單目錄

    sidebar: {
      open: true
    },
    // debounce 等待時間
    debounceDelayTime: 2000

  },

  getters: {
    sidebar: state => state.sidebar
  },
  mutations: {
    TOGGLE_SIDEBAR: state => {
      state.sidebar.open = !state.sidebar.open
    },
    //是否已登入
    setIsAuthenticated(state,isAuthenticated){
      state.isAuthenticated = isAuthenticated;
    },
    getIsAuthenticated(state){
      return state.isAuthenticated;
    },
    //登入帳號
    setCurrentAccount(state,currentAccount){
      state.currentAccount = currentAccount;
    },
    getCurrentAccount(state){
      return state.currentAccount;
    },
    //登入帳號擁有之充電站
    setChargingStations(state,chargeStations){
      state.chargingStations = chargeStations;
    },
    getChargingStations(state){
      return state.chargingStations;
    },
    //登入帳號擁有之充電樁
    setChargingPoints(state,chargePoints){
      state.chargingPoints = chargePoints;
    },
    getChargingPoints(state){
      return state.chargingPoints;
    },
    //登入帳號擁有之充電槍
    setChargingConnectors(state,chargeConnectors){
      state.chargingConnectors = chargeConnectors;
    },
    getChargingConnectors(state){
      return state.chargingConnectors;
    },
    //角色可用選單
    setRoleMenus(state,menus){
      state.menu = menus;
    },
    getRoleMenus(state){
      return state.menu;
    },
    //所有選單目錄
    setAllMenu(state,menus){
      state.allMenu = menus;
    },
    getAllMenu(state){
      return state.allMenu;
    },

  },
  actions: {
    toggleSidebar({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    // EvCharge:取得所有選單目錄
    async getAllMenu({ commit }) {
      return Vue.axios.get('/api/system/menu').then(response => {
        //console.log('getAllMenu', response)
        //state.allMenu = response.data
        commit('setAllMenu',response.data);
        // console.log(state.allMenu)
        return true
      }).catch( error => {
        console.err(error)
        return false
      })
    },
    // EvCharge:取得當前使用者可使用的選單目錄
    async getMenuByCurrentAccount({ commit }) {
      console.log('getMenuByCurrentAccount')
      return Vue.axios.get('/api/v1/menu/my').then(response => {
        console.log('menu', response)
        //state.menu = response.data
        commit('setRoleMenus',response.data);
        // console.log(state.menu)
        return true
      }).catch( error => {
        console.err(error)
        return false
      })
    },

    // EvCharge:取得登入帳號資訊
    async getCurrentAccount({ commit}) {
      return Vue.axios.get('/api/v1/common/currentAccount').then(res => {
        console.log("getCurrentAccount",res)
        //state.currentAccount = res.data;
        commit('setCurrentAccount',res.data);
        return true
      }).catch( error => {
        console.error(error)
        return false
      })
    },
    // EvCharge:查詢登入帳號所屬營運商所有充電站資訊
    async getStationByCurrentAccount({commit}) {
      return Vue.axios.get('/api/v1/common/findChargingStationByCurrentAccount').then(res => {
        console.log("getStationByCurrentAccount",res)
        //state.chargingStations = res.data;
        commit("setChargingStations",res.data);
        return true
      }).catch( error => {
        console.error(error)
        return false
      })
    },
    // EvCharge:查詢登入帳號所屬營運商所有充電樁資訊
    async getPointByCurrentAccount({commit}) {
      return Vue.axios.get('/api/v1/common/findChargingPointByCurrentAccount').then(res => {
        console.log("getPointByCurrentAccount",res)
        commit("setChargingPoints",res.data);
        return true
      }).catch( error => {
        console.error(error)
        return false
      })
    },
    // EvCharge:查詢登入帳號所屬營運商所有充電槍資訊
    async getConnectorByCurrentAccount({commit}) {
      return Vue.axios.get('/api/v1/common/findChargingConnectorByCurrentAccount').then(res => {
        console.log("getConnectorByCurrentAccount",res)
        commit("setChargingConnectors",res.data);
        return true
      }).catch( error => {
        console.error(error)
        return false
      })
    },

  },
  modules: {

  }
})
