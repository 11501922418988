// const { defineConfig } = require('@vue/cli-service')
const path = require('path')

function resolve (dir) {
  return path.join(__dirname, dir)
}

module.exports = {

  //publicPath: "/",
  publicPath: "./",  // 相對路徑
  // publicPath: "/evcharge/",
  // outputDir: '../main/resources/static',
  outputDir: 'dist',

  devServer: {
    host: 'localhost',
    port: 8081,
    open: true,
    proxy: {
      '/api': {
        target: 'http://localhost:6783',
        changeOrigin: true,
        pathRewrite: {
          '^/api': 'api'
        }
      },
      '/kongapi': {
        target: 'http://10.174.183.71',
        changeOrigin: true,
        pathRewrite: {
          '^/kongapi': 'kongapi'
        }
      },
      '/auth': {
        target: 'http://localhost:6783',
        changeOrigin: true,
        pathRewrite: {
          '^/auth': 'auth'
        }
      },
      // '/v1/auth': {
      //   target: 'http://localhost:6783',
      //   changeOrigin: true,
      //   pathRewrite: {
      //     '^/v1/auth': 'v1/auth'
      //   }
      // },
      '/logout': {
        target: 'http://localhost:6783',
        changeOrigin: true,
        pathRewrite: {
          '^/logout': 'logout'
        }
      },
      '/ocpi' : {
        target: 'http://localhost:9002',
        changeOrigin: true,
        pathRewrite: {
          '^/ocpi': 'ocpi'
        }
      },
    }
  },
  // configureWebpack: {
  //   resolve: {
  //     fallback: {
  //       path: require.resolve("path-browserify")
  //     },
  //   }
  // },
  chainWebpack(config) {
    // set svg-sprite-loader
    // config.module
    //   .rule('svg')
    //   .exclude.add(resolve('src/assets/icons'))
    //   .end()
    // config.module
    //   .rule('icons')
    //   .test(/\.svg$/)
    //   .include.add(resolve('src/assets/icons'))
    //   .end()
    //   .use('svg-sprite-loader')
    //   .loader('svg-sprite-loader')
    //   .options({
    //   symbolId: 'icon-[name]'
    //   })
    //   .end()
    // 先刪除預設的svg配置
    config.module.rules.delete("svg")

    // 新增 svg-sprite-loader 設定
    config.module
      .rule("svg-sprite-loader")
      .test(/\.svg$/)
      .include
      .add(resolve("src/assets/icons"))
      .end()
      .use("svg-sprite-loader")
      .loader("svg-sprite-loader")
      .options({ symbolId: "[name]" })

    // // 修改 images-loader 配置
    // config.module
    //   .rule("images")
    //     .test(/\.(png|jpeg|jpg)$/)
    //
    //   .exclude.add(resolve("src/assets/icons"))
    //     .end()
    //     .use("file-loader")
    //     .loader("file-loader").options({
    //   limit: 1024*10,
    // })
  }

}
