<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import * as main from "@/utils/main";
import router from "@/router";
const authUrl = main.authUrl();
import {refreshToken, stopRefreshToken, logout, justDoRefreshToken} from "@/utils/auth";
import Vue from "vue";

export default {
  name: 'App',
  data() {
    return {
      loginTimeout: 0,
    }
  },
  beforeCreate() {
    console.log('App beforeCreate')
    //sessionStorage.removeItem("csrf_token");
  },
  async created() {
    console.log('App created')
    //sessionStorage.removeItem("csrf_token");
    console.log("environment=",process.env.VUE_APP_ENVIRONMENT);
    if(sessionStorage.getItem("csrf_token"))
    {
      //定時更新JWT
      //await justDoRefreshToken();
      await refreshToken();
    }

    // Create a MediaQueryList object,當解析度為700px以下時,將menu選單摺疊,節省空間
    var vt = this;
    this.mediaPixelMatch = window.matchMedia("(max-width: 700px)")
    this.mediaPixelMatch.addEventListener("change", function() {
      vt.mediaMatchFunction(vt.mediaPixelMatch);
    });
    this.mediaMatchFunction(vt.mediaPixelMatch);

  },
  mounted() {
    console.log('mounted')
    this.handleUserActivity() // 一進入系統即呼叫
    window.addEventListener('click', this.handleUserActivity);
    window.addEventListener('keydown', this.handleUserActivity);
  },
  updated() {
    //console.log('updated')
    //sessionStorage.removeItem("csrf_token");
  },
  beforeDestroy() {
    console.log('beforeDestroy')
    // 在組件被銷毀時，移除事件監聽
    window.removeEventListener('click', this.handleUserActivity);
    window.removeEventListener('keydown', this.handleUserActivity);
    sessionStorage.removeItem("csrf_token");
    stopRefreshToken();

  },
  methods: {

    handleUserActivity() {
      // 重置計時器，避免觸發無響應行為時返回首頁
      clearTimeout(this.loginTimeout);
      // 設置計時器，在一段時間內無用戶操作時，返回首頁
      this.loginTimeout = setTimeout(() => {
        //location.href = "/login";
        console.log('已閒置過久，自動轉導登入頁面 ', this.loginTimeout )
        logout();

      }, 2400000); // 2400000ms,40分鐘，根據你的需求進行設定
    },
    //mediaQueryFunction控制UI選單展開/摺疊
    mediaMatchFunction(x) {
      console.log('x',x);
      if (x !== undefined && x.matches) {
        // If media query matches,解析度為700px以下,將menu選單摺疊
        if(this.$store.state.sidebar.open)
        {
          this.$store.dispatch('toggleSidebar');
        }

      }
      //解析度大於700px,將menu選單展開
      else
      {
        if(!this.$store.state.sidebar.open)
        {
          this.$store.dispatch('toggleSidebar');
        }
      }
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


</style>
