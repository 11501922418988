<template>
   <el-container style="height:100vh; border: 1px solid #eee">
        <el-aside :width="isCollapse? '64px': '300px'">
            <Sidebar/>
        </el-aside>
        <el-container>
            <el-header style="text-align: center; font-size: 16px; padding: 0px 10px">
                <Header/>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import { mapGetters } from 'vuex'
import Header from './component/Header'
import Sidebar from './component/Sidebar'
export default {
  name: 'Layout',
  components: { Header, Sidebar },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    isCollapse() {
      return !this.sidebar.open
    }
  },
  
}

</script>
<style>
.el-header {
    background-color: #DAE8F1;
    color: #20638C;
    line-height: 60px;
  }
.el-aside {
    color: #DAE8F1;
  }

</style>
